import React from 'react';

const TaxIcons = {
  corporate: (props) => {
    return (
      <svg {...props} viewBox="0 0 125 125">
        <path fill="#e5e5e5" d="M62.5,0h0A62.5,62.5,0,0,1,125,62.5h0A62.5,62.5,0,0,1,62.5,125h0A62.5,62.5,0,0,1,0,62.5H0A62.5,62.5,0,0,1,62.5,0Z" />
        <path fill="#014d41" d="M79.46,69.79,68.42,43a1.58,1.58,0,0,0-3,.75l3.16,33.13a1.58,1.58,0,0,0,2.56,1.08L79,71.62A1.58,1.58,0,0,0,79.46,69.79Z" />
        <path fill="#006a5c" d="M55.9,43.57H68.55l7.63-11.79a1.58,1.58,0,0,0-1.34-2.41H49.6a1.58,1.58,0,0,0-1.34,2.41Z" />
        <path fill="#009688" d="M68.55,43.57H55.9L49.62,84.35a1.58,1.58,0,0,0,.53,1.44l11,9.47a1.58,1.58,0,0,0,2.05,0l11-9.47a1.58,1.58,0,0,0,.53-1.44Z" />
      </svg>
    );
  },
  individual: (props) => {
    return (
      <svg {...props} viewBox="0 0 125 125">
        <path fill="#e5e5e5" d="M62.5,0h0A62.5,62.5,0,0,1,125,62.5h0A62.5,62.5,0,0,1,62.5,125h0A62.5,62.5,0,0,1,0,62.5H0A62.5,62.5,0,0,1,62.5,0Z" />
        <rect fill="#eac3a2" x="63.8" y="54.69" width="13.02" height="15.63" />
        <path fill="#317e3d" d="M50.77,92.45a1.29,1.29,0,0,1-1.29-1.31V74.88A6.92,6.92,0,0,1,53,69.21L63.8,63.8a9.44,9.44,0,0,0,13,0l10.82,5.41a6.94,6.94,0,0,1,3.5,5.67V91.14a1.31,1.31,0,0,1-1.29,1.31Z" />
        <path fill="#ead8c5" d="M80.73,50.78a10.42,10.42,0,0,1-20.83,0V46.88a10.42,10.42,0,0,1,20.83,0Z" />
        <path fill="#444444" d="M80.73,48.18V41.67a5.22,5.22,0,0,0-5.21-5.21h-.65L73.5,33.72A1.43,1.43,0,0,0,71.72,33l-8.19,3.28a6.22,6.22,0,0,0-3.63,5.36v6.51A5.21,5.21,0,0,1,65.1,43H75.52A5.21,5.21,0,0,1,80.73,48.18Z" />
        <path fill="#87613e" d="M41.67,59.9a13,13,0,0,1,26,0V70.3a5.21,5.21,0,0,1-5.21,5.22H46.87a5.21,5.21,0,0,1-5.21-5.22Z" />
        <rect fill="#eac3a2" x="48.18" y="67.71" width="13.02" height="14.86" />
        <path fill="#4daf4e" d="M35.15,92.45a1.3,1.3,0,0,1-1.29-1.3V87.89a6.91,6.91,0,0,1,3.5-5.66l10.82-5.41a9.44,9.44,0,0,0,13,0L72,82.23a6.93,6.93,0,0,1,3.5,5.66v3.25a1.3,1.3,0,0,1-1.29,1.3Z" />
        <path fill="#ead8c5" d="M44.27,62v1.89a10.42,10.42,0,0,0,20.83,0V62a2.84,2.84,0,0,0-2.84-2.84,8.52,8.52,0,0,1-7.58-4.61,8.52,8.52,0,0,1-7.58,4.61A2.84,2.84,0,0,0,44.27,62Z" />
      </svg>
    );
  },
  property: (props) => {
    return (
      <svg {...props} viewBox="0 0 125 125">
        <path fill="#e5e5e5" d="M62.5,0L62.5,0C97,0,125,28,125,62.5l0,0C125,97,97,125,62.5,125l0,0C28,125,0,97,0,62.5l0,0C0,28,28,0,62.5,0z"/>
        <path fill="#b3b3b3" d="M75.5,61.2c-0.7,0-1.3-0.6-1.3-1.3c0,0,0,0,0,0v-7.8c0-2.2,1.8-3.9,3.9-3.9h2.6c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3h-2.6c-0.7,0-1.3,0.6-1.3,1.3v7.8C76.9,60.6,76.3,61.2,75.5,61.2C75.5,61.2,75.5,61.2,75.5,61.2z"/>
        <path fill="#ef4438" d="M67.7,61.2H46.8c-0.7,0-1.3-0.6-1.3-1.3V39c0-0.7,0.6-1.3,1.3-1.3h15.7c0.6,0,1.1,0.4,1.3,1L69,59.6c0.2,0.7-0.2,1.4-0.9,1.6C68,61.2,67.8,61.2,67.7,61.2L67.7,61.2z"/>
        <path fill="#ef4438" d="M83.4,79.5H46.8c-0.7,0-1.3-0.6-1.3-1.3l0,0V59.9c0-0.7,0.6-1.3,1.3-1.3h35.2c1.4,0,2.6,1.2,2.6,2.6l0,0v17C84.7,78.9,84.1,79.5,83.4,79.5z"/>
        <path fill="#ef4438" d="M46.8,40.3h-5.2c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h5.2c0.7,0,1.3,0.6,1.3,1.3S47.6,40.3,46.8,40.3z"/>
        <path fill="#5b5b5b" d="M46.8,87.3c-7.9,0-14.3-6.4-14.3-14.4s6.4-14.3,14.4-14.3S61.2,65,61.2,72.9l0,0C61.2,80.9,54.8,87.3,46.8,87.3z"/>
        <path fill="#b3b3b3" d="M46.8,80.8c-4.3,0-7.8-3.5-7.8-7.8s3.5-7.8,7.8-7.8c4.3,0,7.8,3.5,7.8,7.8S51.2,80.8,46.8,80.8L46.8,80.8z"/>
        <path fill="#5b5b5b" d="M83.4,87.3c-5,0-9.1-4.1-9.1-9.1s4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1v0C92.5,83.2,88.4,87.3,83.4,87.3z"/>
        <circle fill="#b3b3b3" cx="83.4" cy="78.2" r="3.9"/>
        <path fill="#76b5b5" d="M58.1,58.6H66l-4.5-18.3H48.2v14.4C51.8,55,55.3,56.3,58.1,58.6z"/>
        <circle fill="#5b5b5b" cx="46.8" cy="72.9" r="2.6"/>
      </svg>
    );
  },
  sales: (props) => {
    return (
      <svg {...props} viewBox="0 0 125 125">
        <path fill="#e5e5e5" d="M62.5,0h0A62.5,62.5,0,0,1,125,62.5h0A62.5,62.5,0,0,1,62.5,125h0A62.5,62.5,0,0,1,0,62.5H0A62.5,62.5,0,0,1,62.5,0Z" />
        <path fill="#b3b3b3" d="M87.24,78.13H41.67a1.3,1.3,0,0,1-1.13-1.95l5.21-9.11A1.3,1.3,0,1,1,48,68.35l-4.09,7.17H87.24a1.3,1.3,0,1,1,0,2.6Z" />
        <path fill="#b3b3b3" d="M44.27,45.57a1.3,1.3,0,0,1-1.17-.72l-2.32-4.64L34.9,39a1.3,1.3,0,1,1,.51-2.55l6.51,1.3a1.3,1.3,0,0,1,.91.69l2.6,5.21a1.3,1.3,0,0,1-1.16,1.88Z" />
        <path fill="#223e49" d="M46.88,80.73a5.21,5.21,0,1,0,5.21,5.21A5.21,5.21,0,0,0,46.88,80.73Z" />
        <path fill="#223e49" d="M82,80.73a5.21,5.21,0,1,0,5.21,5.21A5.21,5.21,0,0,0,82,80.73Z" />
        <circle fill="#ffffff" cx="46.87" cy="85.94" r="2.6" />
        <circle fill="#ffffff" cx="82.03" cy="85.94" r="2.6" />
        <path fill="#f37021" d="M84.64,69H46.88a1.3,1.3,0,0,1-1.29-1.16L43,44.41A1.3,1.3,0,0,1,44.39,43l44.27,3.91a1.3,1.3,0,0,1,1.16,1.55L85.91,68A1.3,1.3,0,0,1,84.64,69Z" />
        <circle fill="#fec111" cx="80.73" cy="45.57" r="10.42" />
        <path fill="#ffffff" d="M84.64,44.27H82v-2.6a1.3,1.3,0,1,0-2.6,0v2.6h-2.6a1.3,1.3,0,1,0,0,2.6h2.6v2.6a1.3,1.3,0,0,0,2.6,0v-2.6h2.6a1.3,1.3,0,0,0,0-2.6Z" />
      </svg>
    );
  },
  unemployment: (props) => {
    return (
      <svg {...props} viewBox="0 0 125 125">
        <path fill="#e5e5e5" d="M62.5,0L62.5,0C97,0,125,28,125,62.5l0,0C125,97,97,125,62.5,125l0,0C28,125,0,97,0,62.5l0,0C0,28,28,0,62.5,0z"/>
        <path fill="#ac6cad" d="M84,50.2c0-1.2-1-2.3-2.3-2.3l0,0H42.1c-1.9,0-3.4-1.5-3.4-3.4v0c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1v36.2c0,4.4,3.6,7.9,7.9,7.9l0,0h37.4c1.2,0,2.3-1,2.3-2.3c0,0,0,0,0,0V50.2z"/>
        <path fill="#903f98" d="M87.4,62.7H76.1c-3.1,0-5.7,2.5-5.7,5.7c0,3.1,2.5,5.7,5.7,5.7c0,0,0,0,0,0h11.3c0.6,0,1.1-0.5,1.1-1.1v-9.1C88.5,63.2,88,62.7,87.4,62.7z"/>
        <path fill="#903f98" d="M39.9,41.1h31.7c1.2,0,2.3,1,2.3,2.3l0,0v4.5h-34c-1.9,0-3.4-1.5-3.4-3.4v0l0,0C36.4,42.7,38,41.1,39.9,41.1C39.8,41.1,39.8,41.1,39.9,41.1z"/>
        <circle fill="#efd358" cx="76.1" cy="68.3" r="2.3"/>
        <path fill="#4da34d" d="M77.2,37.7h-34c-0.6,0-1.1,0.5-1.1,1.1c0,0,0,0,0,0v9.1h36.3v-9.1C78.3,38.2,77.8,37.7,77.2,37.7z"/>
        <rect x="45.5" y="41.1" fill="#9edb9e" width="29.4" height="6.8"/>
      </svg>
    );
  }
};

export default TaxIcons;