import React from 'react';

const FourOhFour = (props) => {
  return (
    <div>
      <h1>Page Not Found</h1>
      <p>We're sorry, but we cannot find the page you are looking for.</p>
    </div>
  );
};

export default FourOhFour;
